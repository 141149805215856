<template>
  <el-drawer
    :title="title"
    :visible.sync="visible"
    direction="rtl"
    size="50%"
    @open="drawerOpen"
    @close="drawerClose"
  >
    <el-table
      v-loading="loading"
      fit
      border
      highlight-current-row
      stripe
      :data="tableList"
    >
      <el-table-column
        property="title"
        label="类型名称"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        property="responsiblePersonName"
        label="负责人"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        width="80"
        prop="finishStatus"
        label="状态"
      >
        <template slot-scope="{row}">
          <b-badge
            class="d-flex justify-content-center"
            style="align-items: center"
            pill
            :variant="statusColorObj[row.finishStatus]"
          >
            {{ statusObj[row.finishStatus] }}
          </b-badge>
        </template>
      </el-table-column>
      <el-table-column
        width="80"
        prop="status"
        label="审核状态"
      >
        <template slot-scope="{row}">
          <StatusBadge
            :label="auditStatusObj[row.auditResult]"
            :color="auditStatusColorObj[row.auditResult]"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="100px"
      >
        <template slot-scope="{row}">
          <el-link
            :underline="false"
            class="mr10"
            type="primary"
            @click="preview(row)"
          >
            <i class="el-icon-document" />
          </el-link>
          <el-link
            :underline="false"
            class="mr10"
            type="primary"
            @click="saveDialogShow(row)"
          >
            <i class="el-icon-edit" />
          </el-link>
          <!--          <el-link-->
          <!--            :underline="false"-->
          <!--            type="primary"-->
          <!--            class="mr10"-->
          <!--            @click="del(row)"-->
          <!--          >-->
          <!--            <i class="el-icon-delete" />-->
          <!--          </el-link>-->
        </template>
      </el-table-column>
    </el-table>
    <AuditUpdate
      ref="auditUpdate"
      @handleFilter="getItemList"
    />
  </el-drawer>
</template>
<script>
import { GetItemList } from '@/api/business/business'
import AuditUpdate from '@/views/dataIntelligent/business/AuditUpdate.vue'

export default {
  components: { AuditUpdate },
  data() {
    return {
      visible: false,
      tableList: [],
      title: '',
      loading: false,
      manageId: 0,
      statusObj: {
        1: '待填写',
        2: '填写中',
        3: '填写完成',
        4: '重新填写',
        5: '合规',
      },
      statusColorObj: {
        1: 'warning',
        2: 'danger',
        3: 'success',
        4: 'light-danger',
        5: 'primary',
      },
      auditStatusColorObj: {
        1: 'rgb(255, 73, 73)',
        2: 'rgb(19, 206, 102)',
      },
      auditStatusObj: {
        1: '驳回',
        2: '通过',
      },
    }
  },
  methods: {
    preview(row) {
      const { href } = this.$router.resolve({
        path: '/ixit-privew',
        query: { id: row.id, t: row.type },
      })
      window.open(href, '_blank')
    },
    saveDialogShow(row) {
      this.$refs.auditUpdate.dialogVisible = true
      this.$refs.auditUpdate.form.responsiblePersonId = row.responsiblePersonId ? row.responsiblePersonId : ''
      this.$refs.auditUpdate.form.id = row.id
      this.$refs.auditUpdate.form.status = row.finishStatus
      this.$refs.auditUpdate.title = row.title
    },
    drawerOpen() {
      this.getItemList()
    },
    drawerClose() {
      this.$emit('handleFilter')
      if(this.timer){
        clearInterval(this.timer)
      }
    },
    getItemList() {
      this.loading = true
      GetItemList(this.manageId).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          this.tableList = resData.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
  },
}
</script>
<style lang="scss">

</style>
