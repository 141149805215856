<template>
  <el-dialog
    :title="form.id ? '修改业务数据' : '新增业务数据'"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="120px"
    >
      <el-form-item
        label="名称"
        prop="title"
        :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.title"
          maxlength="100"
        />
      </el-form-item>
      <el-form-item
        label="业务"
        class="form-item-flex"
        prop="productId"
        :rules="[ { required: true, message: '业务不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.productId"
          placeholder="请选择业务"
        >
          <el-option
            v-for="(item,index) in productList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="负责人"
        prop="responsiblePersonId"
        :rules="[ { required: true, message: '人员不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.responsiblePersonId"
          placeholder="请选择人员"
        >
          <el-option
            v-for="(item,index) in users"
            :key="index"
            :label="item.actualName || item.nickName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="预计完成时间"
        prop="planFinishTime"
        :rules="[ { required: true, message: '完成时间不能为空', trigger: 'change' }]"
      >
        <el-date-picker
          v-model="form.planFinishTime"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          type="date"
          :picker-options="afterTodayPickerOptions"
          placeholder="选择日期"
        />
      </el-form-item>
      <el-form-item
        v-if="!form.id"
        label="数据来源"
        class="form-item-flex"
      >
        <el-select
          v-model="form.dateSourceId"
          placeholder="请选择数据来源"
        >
          <el-option
            v-for="(item,index) in ixitOptions"
            :key="index"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { queryUserOptions } from '@/api/system/system'
import { getAllProduct } from '@/api/enterprise/product'
import { saveOrUpdateIxIt, GetIxitOptions, GetManageById } from '@/api/business/business'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      depts: [],
      users: [],
      productList: [],
      ixitOptions: [],
      enterpriseId: 1,
      form: {
        id: 0,
        title: '',
        planFinishTime: '',
        dateSourceId: '',
        responsiblePersonId: '',
        productId: '',
      },
    }
  },
  methods: {
    getProuctOptions() {
      getAllProduct()
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.productList = resData.data
          }
        })
    },
    getIxitOptions() {
      GetIxitOptions({ productId: Number(this.form.productId) })
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.ixitOptions = resData.data
          }
        })
    },
    getItxtDetailsList() {
      GetManageById(this.form.id).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
        }
      })
    },
    dialogOpen() {
      this.getEnterpriseUersSelect()
      this.getProuctOptions()
      this.getIxitOptions()
      if (this.form.id) {
        this.getItxtDetailsList()
      }
    },
    dialogClose() {
      this.form = {
        id: 0,
        title: '',
        planFinishTime: '',
        dateSourceId: '',
        responsiblePersonId: '',
        productId: '',
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.form.dateSourceId = this.form.dateSourceId ? this.form.dateSourceId : 0
          saveOrUpdateIxIt(this.form)
            .then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.$emit('handleFilter')
              } else {
                error(resData.msg)
              }
            })
        }
      })
    },
    getEnterpriseUersSelect() {
      queryUserOptions()
        .then(res => {
          if (res.data.code === 0) {
            this.users = res.data.data
          }
        })
    },
  },
}
</script>

<style>
    .assest-save-form {
        padding-right: 10px;
    }

    .assest-save-form .el-icon-question {
        position: absolute;
        top: 14px;
        right: -20px;
    }
</style>
