<template>
  <div>
    <el-card class="box-card">
      <div class="filter-wrap flex-between">
        <div class="flex-start search-filter-wrap">
          <el-input
            v-model="listQuery.SearchStr"
            width="180"
            placeholder="请输入内容"
            clearable
            @clear="handleFilter"
          />
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="handleFilter"
          >
            搜索
          </el-button>
        </div>
        <el-button
          v-if="savePerm"
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="saveDialogShow(1)"
        >
          新增
        </el-button>
      </div>
      <div class="table-wrap">
        <FilterSelectList
          :list-query.sync="listQuery"
          :select-list.sync="selectList"
          @handleFilter="handleFilter"
        />
        <el-table
          v-loading="loading"
          :max-height="pageH - 250"
          fit
          border
          highlight-current-row
          :data="tableData"
          stripe
          style="width: 100%"
        >
          <el-table-column
            prop="title"
            :show-overflow-tooltip="true"
            label="标题"
          />
          <el-table-column
            prop="productName"
            :show-overflow-tooltip="true"
            label="业务及业务场景"
          />
          <el-table-column
            prop="responsiblePersonName"
            label="负责人"
          />
          <el-table-column
            prop="participantNames"
            label="参与人员"
          />
          <el-table-column
            prop="planFinishTime"
            width="100"
            label="预计完成时间"
          >
            <template slot-scope="scope">
              {{ formatDateStr(scope.row.planFinishTime) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="finishTime"
            width="100"
            label="实际完成时间"
          >
            <template slot-scope="scope">
              {{ formatDateStr(scope.row.finishTime) }}
            </template>
          </el-table-column>
          <el-table-column
            width="80"
            prop="status"
            label="状态"
          >
            <!--                        <template slot="header" slot-scope="scope">-->
            <!--                            <TableHeaderFilterDropdown-->
            <!--                                    :key-str="'status'"-->
            <!--                                    :select-list.sync="selectList"-->
            <!--                                    :label="'状态'"-->
            <!--                                    :value.sync="listQuery.status"-->
            <!--                                    :dropdown-options="statusOption"-->
            <!--                                    @handleFilter="handleFilter"-->
            <!--                            />-->
            <!--                        </template>-->
            <template slot-scope="{row}">
              <StatusBadge
                  :label="statusObj[row.status]"
                  :color="statusColorObj[row.status]"
              />
            </template>
          </el-table-column>
          <el-table-column
              width="80"
              prop="status"
              label="审核状态"
          >
            <template slot-scope="{row}">
              <StatusBadge
                  :label="auditStatusObj[row.auditResult]"
                  :color="auditStatusColorObj[row.auditResult]"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="180px"
          >
            <template slot-scope="{row}">
              <el-link
                v-if="fillPerm && row.status !== 3"
                :underline="false"
                class="mr10"
                type="primary"
                @click="$router.push({ path: '/businessFill', query:{ id: row.id} })"
              >
                <el-tooltip
                  content="填写"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-edit-outline" />
                </el-tooltip>

              </el-link>
              <el-link
                v-if="updatePerm"
                :underline="false"
                class="mr10"
                type="primary"
                @click="saveDialogShow(2,row)"
              >
                <el-tooltip
                  content="修改"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-edit" />
                </el-tooltip>

              </el-link>
              <el-link
                v-if="auditPerm"
                :underline="false"
                class="mr10"
                type="primary"
                @click="auditDialogShow(row)"
              >
                <el-tooltip
                  content="数据详情"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-s-data" />
                </el-tooltip>
              </el-link>
              <el-link
                v-if="delPerm"
                :underline="false"
                type="primary"
                class="mr10"
                @click="del(row)"
              >
                <el-tooltip
                  content="删除"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-delete" />
                </el-tooltip>

              </el-link>
              <el-link
                v-if="exportPerm && row.status === 3 && row.auditResult === 2"
                :underline="false"
                type="primary"
                @click="downloadFile(row.id)"
              >
                <el-tooltip
                  content="导出文件"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-download" />
                </el-tooltip>

              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="total>0"
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.pageSize"
          @pagination="getList"
        />
      </div>

    </el-card>
    <BusinessSave
      ref="businessSave"
      @handleFilter="pageRensh"
    />
    <AuditDrawer
      ref="auditDrawer"
      @handleFilter="getList"
    />
  </div>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import BusinessSave from '@/views/dataIntelligent/business/BusinessSave.vue'
import {
  error, getBtnPerms, success, verifyBtnPerm,
} from '@core/utils/utils'
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList.vue'
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown.vue'
import AuditDrawer from '@/views/dataIntelligent/business/AuditDrawer.vue'
import { IxitPage, DelIxit, DownloadAttachment, IxitManageExport } from '@/api/business/business'

export default {
  components: {
    pagination,
    BusinessSave,
    PageIntroduce,
    FilterSelectList,
    TableHeaderFilterDropdown,
    AuditDrawer,
  },

  data() {
    return {
      statusObj: {
        1: '待填写',
        2: '填写中',
        3: '填写完成',
        4: '重新填写',
      },
      statusColorObj: {
        1: '#82868b',
        2: '#0d40b2',
        3: '#3CB371',
        4: '#ff6633',
      },
      auditStatusColorObj: {
        1: 'rgb(255, 73, 73)',
        2: 'rgb(19, 206, 102)',
      },
      auditStatusObj: {
        1: '驳回',
        2: '通过',
      },
      userOptions: [],
      selectList: [],
      savePerm: false,
      updatePerm: false,
      readPerm: false,
      fillPerm: false,
      delPerm: false,
      auditPerm: false,
      exportPerm: false,
      statusOptions: [
        {
          label: '待填写',
          value: 0,
        },
        {
          label: '采集中',
          value: 1,
        },
        {
          label: '采集完成',
          value: 2,
        },
      ],
      loading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
      },
      tableData: [],
      series: [],
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.savePerm = verifyBtnPerm(btnPerms, 'business-1')
      this.updatePerm = verifyBtnPerm(btnPerms, 'business-2')
      this.delPerm = verifyBtnPerm(btnPerms, 'business-3')
      this.fillPerm = verifyBtnPerm(btnPerms, 'business-4')
      this.auditPerm = verifyBtnPerm(btnPerms, 'business-5')
      this.exportPerm = verifyBtnPerm(btnPerms, 'business-6')
      this.readPerm = verifyBtnPerm(btnPerms, 'business-7')
    })
    this.getList()
  },
  methods: {
    auditDialogShow(row) {
      this.$refs.auditDrawer.visible = true
      this.$refs.auditDrawer.manageId = row.id
      this.$refs.auditDrawer.title = row.title
    },
    downloadFile(id) {
      IxitManageExport(id)
    },
    del(row) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          const ids = []
          ids.push(row.id)
          DelIxit({ ids })
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.handleFilter()
              } else {
                error(resData.msg)
              }
            })
        })
    },
    saveDialogShow(type, row) {
      this.$refs.businessSave.dialogVisible = true
      if (type === 2) {
        this.$refs.businessSave.form.id = row.id
      }
    },
    getList() {
      this.loading = true
      IxitPage(this.listQuery)
        .then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    pageRensh() {
      this.getList()
    },
  },
}
</script>
<style>
    .cell .el-tooltip {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: keep-all;
        width: 100%;
    }
</style>
