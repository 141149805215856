<template>
  <el-dialog
    title="修改"
    :visible.sync="dialogVisible"
    width="620px"
    :append-to-body="true"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form mr10"
      label-width="100px"
    >
      <el-form-item
        label="类型"
      >
        <span>{{ title }}</span>
        <!--        <el-select v-model="form.id">-->
        <!--          <el-option-->
        <!--            v-for="(item,index) in typeOptions"-->
        <!--            :key="index"-->
        <!--            :label="item.name"-->
        <!--            :value="item.id"-->
        <!--          />-->
        <!--        </el-select>-->
      </el-form-item>
      <el-form-item
        label="负责人"
        prop="responsiblePersonId"
        :rules="[ { required: true, message: '请选择负责人', trigger: 'change' }]"
      >
        <el-select
          v-model="form.responsiblePersonId"
          placeholder="请选择人员"
        >
          <el-option
            v-for="(item,index) in users"
            :key="index"
            :label="item.actualName || item.nickName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="状态"
        prop="status"
        :rules="[ { required: true, message: '请选择状态', trigger: 'change' }]"
      >
        <el-select v-model="form.status">
          <el-option
            v-for="(item,index) in stautusOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        保 存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { ItemUpdate } from '@/api/business/business'
import { queryUserOptions } from '@/api/system/system'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {
        id: '',
        status: '',
        responsiblePersonId: '',
      },
      title: '',
      typeOptions: [],
      users: [],
      stautusOptions: [
        { label: '待填写', value: 1 },
        { label: '填写中', value: 2 },
        { label: '填写完成', value: 3 },
        { label: '重新填写', value: 4 },
        { label: '合规', value: 5 },
      ],
    }
  },
  methods: {
    dialogOpen() {
      this.getEnterpriseUersSelect()
    },
    getEnterpriseUersSelect() {
      queryUserOptions()
        .then(res => {
          if (res.data.code === 0) {
            this.users = res.data.data
          }
        })
    },
    dialogClose() {
      this.form = {
        id: '',
        status: '',
        responsiblePersonId: '',
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          ItemUpdate(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}
.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
</style>
